<template>
  <NuxtLink :to="path" class="flex justify-center items-center">
    <svg
      width="75"
      height="32"
      viewBox="0 0 75 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_6991_71501"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="29"
        y="2"
        width="22"
        height="28"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M29.9331 2H50.0551V29.9995H29.9331V2Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6991_71501)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M35.8566 7.75929V8.31524C35.8566 15.2056 35.8568 22.0959 35.8565 28.9862C35.8565 29.8 35.6733 29.9987 34.9238 29.999C33.5473 29.9995 32.1708 30 30.7944 29.9987C30.1493 29.998 29.934 29.7744 29.9338 29.0953C29.9328 20.3664 29.9328 11.6377 29.934 2.90892C29.9341 2.25274 30.1528 2.01852 30.7637 2.01812C33.1582 2.01611 35.5535 1.97189 37.9469 2.02924C40.7052 2.0953 43.3199 2.76032 45.5739 4.58855C47.8358 6.42322 49.1088 8.93269 49.6486 11.8771C50.3764 15.8482 50.1863 19.7586 48.5927 23.4746C46.9452 27.3161 44.1199 29.4742 40.1985 29.9214C39.7154 29.9764 39.2265 29.9923 38.7403 29.9935C38.2825 29.9947 38.0326 29.7472 38.0274 29.259C38.0123 27.8688 38.0132 26.4782 38.0269 25.0879C38.0317 24.5975 38.2752 24.3505 38.7363 24.3518C41.1459 24.3581 42.5735 22.9449 43.2569 20.7504C44.1395 17.9162 44.2082 15.0085 43.5325 12.1218C42.9115 9.46841 41.2123 7.98936 38.679 7.77175C37.7698 7.69377 36.8498 7.75929 35.8566 7.75929Z"
          fill="white"
        />
      </g>
      <mask
        id="mask1_6991_71501"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="6"
        y="2"
        width="23"
        height="28"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 2.017H28.3791V30H6V2.017Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_6991_71501)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.0435 11.0944C16.3223 13.8287 15.601 16.563 14.8651 19.3527C15.4256 19.3527 15.9235 19.3487 16.4214 19.3538C16.9462 19.3593 17.2176 19.5738 17.3395 20.1284C17.5734 21.1941 17.8016 22.2615 18.0052 23.3342C18.1556 24.1266 17.8178 24.5553 17.0716 24.5604C16.028 24.5674 14.9841 24.5769 13.9408 24.5539C13.6098 24.5466 13.4681 24.6609 13.3803 25.0066C13.0292 26.3896 12.6503 27.7644 12.2801 29.1417C12.1075 29.784 11.8476 29.9976 11.2318 29.9985C9.81649 30.0005 8.40113 30.0005 6.98577 29.9986C6.15308 29.9974 5.82293 29.5005 6.09144 28.633C7.79428 23.1343 9.50135 17.6373 11.2058 12.139C12.1395 9.12708 13.0706 6.11432 14.0029 3.10183C14.2923 2.16654 14.4839 2.01727 15.3971 2.01727C16.6121 2.01714 17.8273 2.0166 19.0426 2.01754C19.7512 2.01794 20.0079 2.20875 20.2397 2.94667C21.2991 6.32001 22.3526 9.69549 23.4099 13.0696C25.0347 18.2545 26.6601 23.439 28.2867 28.6232C28.5596 29.4927 28.2215 29.9982 27.3573 29.9989C25.9278 29.9999 24.498 29.9997 23.0684 29.9989C22.3346 29.9985 22.0724 29.7907 21.8699 29.0245C20.3302 23.1965 18.7929 17.3679 17.2548 11.5395C17.216 11.3921 17.1752 11.2453 17.1354 11.0982C17.1047 11.097 17.0741 11.0957 17.0435 11.0944Z"
          fill="white"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M59.1942 21.5938C58.8093 21.9072 58.1732 22.2208 57.1524 22.2208C55.3785 22.2208 54.3912 21.0407 54.3912 18.8833V14.7344H53.4039C53.186 14.7344 53.069 14.6052 53.069 14.3656V12.7983C53.069 12.5584 53.186 12.4295 53.4039 12.4295H54.3912V10.143C54.3912 9.90332 54.5079 9.7741 54.7257 9.7741H56.5833C56.8512 9.7741 56.985 9.90332 56.985 10.143V12.4295H61.4237C61.6412 12.4295 61.7586 12.5584 61.7586 12.7983V14.3656C61.7586 14.6052 61.6412 14.7344 61.4237 14.7344H56.985V18.5328C56.985 19.455 57.1692 19.7129 57.7215 19.7129C57.889 19.7129 58.0899 19.6577 58.374 19.5287C58.5915 19.3997 58.7756 19.455 58.8426 19.6946L59.3447 21.0223C59.4117 21.2619 59.378 21.4464 59.1942 21.5938Z"
        fill="#F2084B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M68.9783 13.4105L66.0376 21.8769C65.949 22.0942 65.7898 22.2208 65.5592 22.2208H63.7524C63.5222 22.2208 63.3626 22.0942 63.274 21.8769L60.3331 13.4105C60.2448 13.1572 60.4221 12.8858 60.7053 12.8858H62.6895C62.9197 12.8858 63.0792 13.0124 63.15 13.2295L64.6733 18.476L66.2326 13.2295C66.3031 13.0124 66.4628 12.8858 66.693 12.8858H68.6061C68.8723 12.8858 69.0669 13.139 68.9783 13.4105Z"
        fill="#F2084B"
      />
    </svg>
  </NuxtLink>
</template>

<script setup>
const localePath = useLocalePath();
const path = computed(() => {
  const isKidsMode = useIsKidsMode();
  if (isKidsMode.value) {
    return localePath("/kids");
  }

  return localePath("/");
});
</script>
